<template>
  <div>
    <Banner title="下载专区" hint="提供资料、文档、驱动下载">
      <img class="banner-bg" style="width: 548px ;height: 394px;" src="@/assets/image/helpAndDownload/download-banner-bg.png" alt="下载专区" srcset="">
    </Banner>
    <div class="title">
      常用下载
    </div>
    <div class="main-box">
      <div class="icon-animation-box" @click="$router.push({name:'downloadList', query: {type:item.id}})" v-for="(item,index) in links" :key="index">
        <div class="icon-animation icon" :style="{'background-image': `url(${require('@/assets/icons/helpAndDownload/download-icon'+(index+1)+'.png')})`}"></div>
        <div>{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      links: [
        {
          name: '驱动下载',
          id: '1'
        },
        {
          name: '业务表格',
          id: '2'
        },
        {
          name: '证书链下载',
          id: '3'
        },
        {
          name: '操作手册',
          id: '4'
        },
        {
          name: '更多',
          id: '1'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 36px;
  color: #303b50;
  line-height: 48px;
  letter-spacing: 1px;
  margin-bottom: 110px;
  margin-top: 120px;
  text-align: center;
}
.main-box {
  display: flex;
  margin-bottom: 160px;
  justify-content: space-between;
  & > div {
    width: 240px;
    height: 202px;
    background: #f9fbff;
    box-shadow: 0px 4px 8px 0px rgba(31, 37, 81, 0.11);
    border-radius: 5px;
    border: 4px solid #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    color: #303b50;
    line-height: 31px;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
    .icon {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      margin-bottom: 4px;
    }
  }
}
</style>
